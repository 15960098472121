@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.task-details {
    &__section {
        // display: flex;
        margin-top: 32px;

        .tab {
            overflow: auto;
            display: inline-flex;
            -ms-overflow-style: none;
            scrollbar-width: none;
            border-radius: 12px 12px 0 0;
            box-shadow: none;
            margin: 0 var(--side-width);
        }

        .main-section {
            @include card;
            padding-right: 10%;
            // width: 100%;
            @include respond-below(xs) {
                .important-fields > .full {
                    display: block;
                    margin: 8px 0;
                }
                .important-fields > div > div {
                    margin: 8px 0 0;
                }
            }

            & > div {
                // border-bottom: 1px solid #CFCFCF;
                content: '';
                margin-bottom: 30px;

                &:last-child {
                    border: 0;
                }
            }

        }

        .approval-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .storeAndShowActivity{
            border-radius: 0 12px 12px 12px !important;
        }

        .tooltip-docinfo {
            background-color: #fff;
            color: black;
            border: 1px solid black;
            padding: 6px 8px 2px 8px;
            border-radius: 4px;
            max-width: 300px;
            text-align: left; 
            transform: translateY(-7px);
        }

        .doc-history {
            background-color: white;
            padding: 25px 32px;
            margin: 0;
            margin-left: var(--side-width);
            margin-right: var(--side-width);
        }
    }
}
