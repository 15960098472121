@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.document-details {
    &__section {
        display: flex;
        margin-top: 32px;

        @include respond-below(xs) {
            flex-direction: column-reverse;
            margin: 12px var(--side-width) 0;
        }
        .sidebar,
        .main-section,
        .doc-history {
            .document-details-header {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;
                align-items: space-between;
            }
            @include card;
            @include respond-below(xs) {
                .important-fields > div {
                    display: block; // flex-direction: column;
                    margin: 8px 0;
                }
                .important-fields > div > div {
                    margin: 8px 0 0;
                }
            }
            .check-out-info{
                font-size: 15px;
                color: #856404;
                background-color: #fff3cd;
                border-color: #ffeeba;
                padding: 0.5rem;
                margin-top: 10px;
                margin-bottom: 1px;
                border: solid transparent;
                border-radius: 0.35rem;
            }
        }

        .sidebar {
            margin-right: 20px;
            overflow-y: auto;
            width: 250px;
            @include respond-below(xs) {
                height: 100% !important;
                margin: 12px 0;
                width: 100%;
            }
        }

        .main-section {
            margin-left: 0;
            overflow-y: auto;
            padding-right: 10%;
            width: 100%;

            & > div {
                // border-bottom: 1px solid #CFCFCF;
                content: '';
                margin-bottom: 30px;

                &:last-child {
                    border: 0;
                }
            }

            .reviewers {
                // ! REVIEWER-FIX
                display: none;
                .reviewers-tab {
                    padding: 12px 32px;
                }
            }
        }

        .document-details-tabs {
            width: 100%;
            margin-right: var(--side-width);
          
            &.hideEventTimeline {
              margin-left: var(--side-width);
            }
        }        
        
        .approvers-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .main-section{
            height: fit-content;
            border-radius:12px;
        }

        .doc-history{
            height: fit-content;
            border-radius:0 12px 12px 12px;
        }

        .storeAndShowActivity{
            border-radius: 0 12px 12px 12px !important;
        }

        @include tabs;
        .tab {
            // ! REVIEWER-FIX
            overflow: auto;
            .reviewers {
                display: none;
            }
            .tab-icons {
                width: 16px;
            }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            border-radius: 12px 12px 0 0;
            box-shadow: none;

            span {
                font-size: 12px;
            }
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        .tab::-webkit-scrollbar{
            display: none;
        }

        .doc-history {
            margin: 0;
            h3 {
                margin-bottom: 10px;
            }
            .loading-state {
                .full {
                    margin: 40px 0;
                }
            }
        }

        .tooltip-docinfo {
            background-color: #fff;
            color: black;
            border: 1px solid black;
            padding: 6px 8px 2px 8px;
            border-radius: 4px;
            max-width: 300px;
            text-align: left; 
            transform: translateY(-7px);
        }

        .doc-history-tab {
            background: #f0f0f0;
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 6px;

            button {
                align-items: center;
                background-color: $white;
                border: none;
                border-radius: 100px;
                color: #7D7D7D;
                display: inline-flex;
                flex-grow: 1;
                font-size: 11px;
                font-weight: 550;
                gap: 4px;
                justify-content: center;
                line-height: 20px;
                margin: 4px;
                text-transform: uppercase;
                transition: .4s ease-out;
                padding: 4px 3em;
                
                &:hover{
                    background:inherit;
                    color: inherit;
                }
    
                &.active {
                    background-color: #F37321;
                    color: $white;
                    transition: .4s ease-out;
                }
            }
        }
    }
}
