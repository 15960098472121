@import '../../assets/scss/mixins';

.workflow-panel {
    @include tabs;
    .tab-data {
        margin-top: 20px;
    }

    // ! REVIEWER-FIX
    .tab {
        display: none;
    }
    .leadership-form-points{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: Enphase Visuelt;
        font-size: 12px;
        padding: 4px 0;
        ::first-letter {
            // this is not working
            color: red;
        }
    }
}

.hide-travelform-approvalStage{
    display: none;
}

.delete-stage{
    cursor: pointer;
}

.stage-card {
    @include card {
        box-shadow: 0 1px 14px -3px rgba(0, 0, 0, 0.25);
        margin: 20px 0;
    }

    .heading {
        display: flex;
        justify-content: space-between;

        span {
            background-color: #fff;
            border-radius: 100px;
            cursor: pointer;
            font-size: 26px;
            position: relative;
        }

        .left_subheading {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .deadline-edit {
                cursor: pointer;
                height: 1.2rem;
            }
        }

        .deadline-update-modal{
            input {
                // width: 1rem;
            }
            .deadline-value {
                display: flex;
            }
        }
    }

    .stage-card__info {
        margin-top: 12px;
        .user-actions {
            display: flex;
            margin-left: 12px;
            .btn {
                border-radius: 100px;
                cursor: pointer;
                height: 24px;
                // margin: 0 6px;
                position: relative;
                width: 24px;
                svg {
                    height: 16px;
                    position: absolute;
                    width: 16px;
                    @include align-center;
                }
                &:hover {
                    background-color: #f4f3f0;
                }
            }
            .deadline-input {
                margin-left: 5px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                gap: 5px;
                input {
                    width: 3.3rem;
                    padding: 5px;
                }
            }
        }

        .exempt {
            font-size: 11px;
        }
        .info {
            p {
                font-family: Enphase Visuelt;
                font-size: 12px;
                font-weight: 400;
            }
            .approvedTimeStamp {
                background-color: #6fb006;
                color: #fff;
                padding: 6px;
                border-radius: 10px;
                margin-left: 15px;
            }
            .rejectedTimeStamp{
                background-color: #D80311;
                color: #fff;
                padding: 6px;
                border-radius: 10px;
            }
        }
    }
}
