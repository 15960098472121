@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.new-doc-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 25px 32px;
    position: relative;
    @include respond-below(xs) {
        overflow: auto;
    }

    h2 {
        margin-bottom: 24px;
    }
    @include respond-below(xs) {
        .important-fields > .full {
            display: block;
            margin: 8px 0;
        }
    }
    .checkout-button {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        @include respond-below(sm) {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        .tooltip-docinfo {
            background-color: black;
            color: white;
            border: 1px solid black;
            padding: 6px 8px 4px 8px;
            border-radius: 4px;
            max-width: 250px;
            text-align: center; 
        }
        button {
            min-width: 80px;
            @include respond-below(sm) {
                flex: 1 1;
                margin: 8px 12px 8px 0;
            }

            &.primary-btn {
                padding: 12px 3em;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.activityDocMain {
    background-color: white;
}
.document-details-tabs {
    width: 100%;
}

.doc-history,
.new-doc-main {
    height: fit-content;
    border-radius: 0 12px 12px 12px;
}

.tooltip-add-stage {
    background-color: #fff;
    color: black;
    border: 1px solid black;
    padding: 6px 8px 5px 8px;
    border-radius: 4px;
    max-width: 300px;
    text-align: left; 
    transform: translateY(-7px);
}

@include tabs;
.tab-in-singlepage {
    // ! REVIEWER-FIX
    overflow: auto;
    display: inline-flex;
    .reviewers {
        display: none;
    }
    .tab-icons {
        width: 16px;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border-radius: 12px 12px 0 0 !important;
    box-shadow: none !important;

    span {
        font-size: 12px;
    }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tab::-webkit-scrollbar {
    display: none;
}

.doc-history {
    background-color: white;
    padding: 25px 32px;
    margin: 0;
    h3 {
        margin-bottom: 10px;
    }
    .loading-state {
        .full {
            margin: 40px 0;
        }
    }
}

.approvals-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doc-history-tab {
    background: #f0f0f0;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 6px;

    button {
        align-items: center;
        background-color: $white;
        border: none;
        border-radius: 100px;
        color: #7d7d7d;
        display: inline-flex;
        flex-grow: 1;
        font-size: 11px;
        font-weight: 550;
        gap: 4px;
        justify-content: center;
        line-height: 20px;
        margin: 4px;
        text-transform: uppercase;
        transition: 0.4s ease-out;
        padding: 4px 3em;

        &:hover {
            background: inherit;
            color: inherit;
        }

        &.active {
            background-color: #f37321;
            color: $white;
            transition: 0.4s ease-out;
        }
    }
}

.doc_submit__modal {
    background-color: $white;
    border-radius: 8px;
    outline: none;
    padding: 12px;
    position: absolute;
    @include align-center;

    .modal-body {
        @include respond-below(xs) {
            min-width: 240px;
            padding: 16px 20px;
            h1 {
                margin-bottom: 16px;
            }
            h4 {
                margin-bottom: 12px;
            }
        }
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 4em 6em 2em;

        .notify-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
            button {
                flex-grow: 1;
            }
        }

        h1 {
            font-size: 20px;
            line-height: 125%;
        }

        P {
            font-size: 12px;
            line-height: 125%;
            margin-bottom: 20px;
        }
    }
}
