@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import "../../assets/scss/media-queries";

.confirmation-modal,
.expiry-modal {
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 24px 16px 24px 32px;
    position: absolute;
    width: 500px;
    @include align-center;
    .modal-body {
        .heading {
            h1 {
                padding-bottom: 16px;
            }
            h4 {
                margin-bottom: 12px;
            }
        }
        .button-group {
            margin-top: 32px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
