@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
.active-sessions {
    /* background-color: #fff; */
    border-radius: 12px;
    box-shadow:
        0 20px 36px -8px #0e0e2c1a,
        0 1px 2px #0000000d;
    margin: 0 var(--side-width);
    margin-top: 12px;
    padding: 25px 32px;
    width: 80%;
    margin-left: 10%;
    .active-sessions-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 10px; /* Reduced margin for better spacing */
        h1 {
            display: inline-flex;
        }
    }
    .active-sessions-table {
        // width: 80%;
        .current-pill {
            background-color: #6cc36c; /* Green background for active state */
            border-color: #59db2adc; /* Green border color for active state */
            border-radius: 8px;
            color: #fff;
            font-family: Enphase Visuelt medium;
            font-size: 11px;
            letter-spacing: 0.5px;
            max-width: 100px;
            padding: 6px 12px;
            text-align: center;
        }
        thead th, tbody tr td{
            text-align: center;
        }
    }
    .warning-message {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow:
            0 20px 36px -8px rgba(14, 14, 44, 0.1),
            0 1px 2px rgba(0, 0, 0, 0.05);
        padding: 20px 12px;
        gap: 12px;
        margin-bottom: 10px;
        // width: 80%;
        .continue-sessions-button {
            margin-left: auto;
        }
        div {
            display: flex;
            flex-direction: column;
            margin-right: auto;
        }
        h1, p {
            margin: 0;
            padding: 5px 0px;
        }
    }
    .destroy-btn{
        margin: 0px;
    }
}
